<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card>
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="8"
                    lg="11"
                    md="10"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                    >
                    </s-scanner-qr>
                </v-col>
            </v-row>
       
        <br>
        <v-row>
            <v-col>
                <v-data-table
                        dense
                        :items="itemsPalletDetail"
                        :headers="HeaderPalletDetail"
                        :items-per-page="-1"                        
                        disable-sort    
                        show-select      
                        item-key="CidID"                        
                        v-model="itemPalletContent"               
                        hide-default-footer>
                        <template v-slot:item.TotalBox="{ item }">
                            <s-text v-model="item.TotalBox" type="number" :min="0" @input="UpdateWeight(item)"  >

                            </s-text>
                        </template>
                        <template v-slot:item.IsObserved="{ item }">
                            <tr v-if="item.IsObserved == 1">
                               <v-chip  x-small class="ma-2" color="red" text-color="white" @click="CargarObservacion(item)">
                                    SI
                               </v-chip>
                            </tr>
                            <tr v-if="item.IsObserved != 1">
                               <v-chip  x-small class="ma-2" color="green" style="font-size: 10px;font-weight: bold;" text-color="white">
                                    NO
                               </v-chip>
                            </tr>
                        </template>
                        
                </v-data-table>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col lg="2" >
                <v-btn  small block left color="primary" dark @click="saveLosse()">
                    <v-icon left small>fas fa-save</v-icon> Ingresar merma
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
        <!-- <v-row>
            <v-col  >
                <v-card>
                    <v-container>
                        <v-row justify="center">
                            <v-col class="col-md-auto center-block"  v-for="(pallet, palletid) in dataPallet.Pallets" :key="palletid">
                            <v-badge color="white">
                                <template v-slot:badge>		
                                    <v-btn	
                                        style="margin-left: -29px;margin-top: 2px;"
                                        fab
                                        small
                                        color="success">
                                        GE
                                    </v-btn>						 
                                </template>
                           
                                <v-card>	
                                    <v-radio-group
                                        v-model="itemPalletLosses"
                                        style="margin-top: 15px; margin-right: 9px 10px;"
                                        hide-details>
                                        <v-radio
                                            :value="pallet.CipID"
                                        ></v-radio>
                                    </v-radio-group>
                            
                                    <v-card-text>
                                        <b>ID Pallet : </b><v-chip>{{pallet.PalletGroupID}}</v-chip>
                                    </v-card-text>
                                 
                                    <v-card-actions>
                                        <v-row justify="space-around" no-gutters>
                                            <v-icon class="mdi-96px" >fa-pallet</v-icon>
                                        </v-row>
                                    </v-card-actions>
                                    <v-card-text class="text-center">
                                        <b>Ubicación : </b> {{pallet.PalletLocation }}<br>
                                        <b>Cultivo : </b> {{pallet.TypeCultiveName}} - {{ pallet.VrtDescription }}<br>
                                        <b>Cliente : </b> {{pallet.CumNombre }}<br>
                                        <b>Marca : </b> {{pallet.CbdBrans }}<br>
                                        <b>Tipo Corte : </b> {{pallet.TypeCutName }}<br>
                                        <b>Presentacion : </b> {{pallet.TypePackingName }}<br>
                                        <b>{{ 'N° Cajas : '+ pallet.NumberBoxes }}</b>
                                        <h4>{{ ' Peso (Kg) : '+ pallet.WeightNetBoxes }}</h4>
                                        <b>T. Congelamiento : </b> {{pallet.CecDescription }}<br>
                                        
                                    </v-card-text>
                                  
                                    <v-card-actions >
                                        <v-btn
                                            color="success"
                                            text
                                            @click=" ShowViewDetails()"
                                            
                                        >
                                            Ver más
                                            <v-icon>{{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                    <v-expand-transition >
                                        <div v-show="showMore">
                                            <v-divider></v-divider>
                                            <v-card-subtitle>
                                                {{ "Detalle de Pallet" }}
                                            </v-card-subtitle>
                                            <v-card-text>
                                                <v-row >
                                                    <v-col cols="auto" align="center" v-for="(palletDetail, detailindex) in pallet.palletDetailCEs" :key="detailindex">
                                                        <v-badge v-if="palletDetail.Temporal == null" :color="palletDetail.ColorObservation" overlap >
                                                            <template v-if="palletDetail.Observation" v-slot:badge>
                                                                <span>OB</span>
                                                            </template>
                                                        <v-card  style="background-color: #9cd1ec;"    >
                                                            
                                                            <v-radio-group
                                                                v-model="itemPalleDetailtLosses"
                                                                style="margin-top: 15px; margin-right: 9px 10px;"
                                                                hide-details>
                                                                <v-radio
                                                                    :value="palletDetail.CidID"
                                                                ></v-radio>
                                                            </v-radio-group>
                                                            <v-card-text>
                                                                <b>ID Pallet : </b><v-chip>{{palletDetail.PalletGroupID}}</v-chip>
                                                            </v-card-text>
                                                            <v-card-text class="text-center">
                                                                <b>Cultivo : </b> {{palletDetail.TypeCultiveName}} - {{ palletDetail.VrtDescription }}<br>                                                               
                                                                <b>Tipo Corte : </b> {{palletDetail.TypeCutName }}<br>
                                                                <b>Presentacion : </b> {{palletDetail.TypePresentationName }}<br>
                                                                <b>{{ 'N° Cajas : '+ palletDetail.TotalBox }}</b>
                                                                <h4>{{ ' Peso (Kg) : '+ palletDetail.TotalBagWeight }}</h4>
                                                                <b>T. Congelamiento : </b> {{palletDetail.CecDescription }}<br>
                                                                <b>E. Final : </b> {{palletDetail.StateFinalString }}<br>
                                                                <b v-if="palletDetail.Observation != null">Observacion : </b> {{palletDetail.Observation }}<br>
                                                            </v-card-text>
                                                            <v-card-actions>
                                                                <v-row justify="space-around" no-gutters>
                                                                    <v-chip color="warning" >{{palletDetail.TraceabilityID}}</v-chip>
                                                                </v-row>
                                                            </v-card-actions>
                                                        									
                                                        </v-card>                                                   
                                                    </v-badge>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </div>
                                    </v-expand-transition>																
                                </v-card>
                            </v-badge>
                        </v-col>
                    </v-row>
                
                </v-container>
                <v-container class="px-0">
                
                </v-container>
            </v-card>
        </v-col> -->
        <v-col cols="auto" >
            <v-card>
                <!-- <v-container>
                <v-row>
                    <v-col align="left" class="s-col-form">
                        <h4>Detalles : </h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto" >
                        <s-text 
                            label="Cantidad de Cajas"
                            number
                            v-model="itemHead.CountBoxLosses"
                            ref="txtCountBoxLosses"
                            @input="CalculateWeights()"
                        ></s-text>
                    </v-col>  
                </v-row>
                <v-row>
                    <v-col cols="auto">
                        <s-text 
                            label="Cantidad de Merma (Kg)"
                            decimal
                            v-model="itemHead.CountLossesKg"
                            ref="txtCountLossesKg"
                            :disabled="true"
                        ></s-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="s-col-form">
                        <v-textarea
                            label="Observaciones"
                            auto-grow
                            variant="outlined"
                            rows="3"
                            row-height="25"
                            shaped
                            v-model="itemHead.Observaciones"
                            ref="txtObservaciones"
                        ></v-textarea>
                    </v-col>
                    <v-col >
                        <v-btn  small block left color="primary" dark @click="saveLosse()" hide-details>
                            <v-icon left small>fas fa-save</v-icon> Ingresar merma
                        </v-btn>
                    </v-col>
                </v-row>
                </v-container> -->
                <v-dialog 
                    v-model="dialogObservation"
                    v-if="dialogObservation"
                    max-width="350"
                    >
                    <v-card outlined>
                        <!-- <v-card-title class="title font-weight-light" >     -->
                        <s-toolbar color="primary" dark close @close="dialogObservation = false" label="Descripcion de Observacion">

                        </s-toolbar>
                        <!-- </v-card-title> -->
                        <v-card-text>
                            <v-row class="s-col-form">
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <h2>Observacion: </h2>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <h3>{{ this.ObservedString }}</h3>
                                        </v-col>                                    
                                    </v-row>
                                </v-col>
                            </v-row>
                            
                            
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-col>        
    </div>
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';
    import _sFrzChamberPalletLosseService from "@/services/FrozenProduction/FrzChamberPalletLosses.js";

    import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";

    export default{
        components: { sScannerQr},
        data:() =>({
            
            clearInput:false,
            configScann: {}, 
            itemHead:{},
            itemPallet: [],
            dataPallet: {
                Pallets:{
                    // PalletsGroups:[],
                },
            },
            WeightNetBoxes:0.0,
            itemPalletLosses: 0,
            itemPalleDetailtLosses: 0,
            itemPalletLossesPacking: 0,
            PalletGroupID:0,
            showMore: false,
            details: [],
            itemPalletContent: [],
            itemsPalletDetail: [],
            HeaderPalletDetail: [
                { text:"Grupo ID", value :"PalletGroupID"},
                { text: "Trazabilidad", value: "TraceabilityID"},
                { text: "Lote", value: "Lote"},
                { text: "Cliente", value: "CumNombre"},
                { text: "Marca", value: "BransName"},
                { text: "Cultivo", value: "TypeCultiveName"},
                { text: "Variedad", value: "VrtDescription"},
                { text: "Tipo de Corte", value: "TypeCutName"},
                { text: "Presentacion", value: "TypePresentationName"},
                { text: "N° Cajas", value: "TotalBox",  width: "100px" },
                { text: "Peso Neto", value: "TotalBagWeight"},
                // { text: "Condicion", value: "IsObservedString"},
                { text: "T. Congelamiento", value: "CecDescription"},
                { text: "Localizacion", value: "PalletLocation" },
                { text: "E. Final", value: "StateFinalString" },
                // { text: "E. Final", value: "StateFinal" },
                { text: "Observado", value: "IsObserved" },
                // { text: "Guardar Rol", value: "saveRoll", align: "center"}

            ],
            dialogObservation: false,
            ObservedString: '',
        }),
        methods:{
            CargarObservacion(item)
            {
                
                this.ObservedString = item.Observation;
                this.dialogObservation = true;

            },
            closedialogObservation()
            {
                this.ObservedString = "";
                this.dialogObservation = false;
            },
            onValue(val)
            {
                
                // if (val == "Sin datos" || isNaN(val)) {
				// 	this.$fun.alert("El QR Leido no contiene datos", "warning");
				// } else
                // {
                //     let item = {};
                //     item.PalletGroupID = val;
                //     this.PalletGroupID = val;
                   
                //     _sFrzChamberPalletLosseService.list(item, this.$fun.getUserID())
                //     .then( r => {
                //         if(r.status == 200)
                //         {
                //             this.dataPallet = r.data;
                //             console.log('detalle pallet',this.dataPallet);
                //         }
                //     });
                // }

                this.PalletGroupID = val;
                if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {

                    _sFrzCameraIncomePallet.getPalletByGroupID({PalletGroupID: val}, this.$fun.getUserID())
                    .then((r) => {
                        if(r.status == 200)
                        {
                            if(r.data.length == 0){
                                this.$fun.alert("Pallet no existe", "warning");
                                return;
                            }
                            
                            r.data[0].palletDetailCEs.forEach(element => {     
                                element.Lote = r.data[0].Lote;
                                element.NumberBoxesOld = element.TotalBox;  
                                if(element.TotalBox > 0 && element.TotalBagWeight > 0)
                                {
                                    this.Validate = true;
                                }
                                element.WeightNetBoxesOld = parseFloat(element.TotalBagWeight);  
                                element.TotalBox = element.TotalBox;         
                                element.TotalBagWeight = element.TotalBagWeight;  
                                element.CecDescription = r.data[0].CecDescription;   
                                element.BransName = r.data[0].BransName; 
                                element.PalletLocation = r.data[0].PalletLocation;   
                                element.PalletGroupID = r.data[0].PalletGroupID;  
                                element.CumNombre = r.data[0].CumNombre; 
                                element.CumID = r.data[0].CumID;      
                                element.CmaID = r.data[0].CmaID; 
                                element.TypeCut = r.data[0].TypeCut;    
                                element.CbdID = r.data[0].CbdID;    
                                element.SideID = r.data[0].SideID; 
                                element.NumberLanes = r.data[0].NumberLanes;     
                                element.NumberLevels = r.data[0].NumberLevels;   
                                element.PositionsLeve = r.data[0].PositionsLeve;    
                                element.CdtID = r.data[0].CdtID;   
                                element.TypeVariant = r.data[0].TypeVariant;    
                                element.TypeProduction = r.data[0].TypeProduction; 
                                // element.StateFinal = r.data[0].StateFinal; 
                                this.itemsPalletDetail.push(element);                    
                            });  
                            
                        }
                    });
                }  
            },
            UpdateWeight(item){
                
                if(parseInt(item.TotalBox) > item.NumberBoxesOld)
                {
                    this.Validate = false;
                    this.$fun.alert("El numero de cajas no puede ser mayor a " + item.NumberBoxesOld, "error");
                    return;
                }
                if(parseInt(item.TotalBox) == 0 || parseInt(item.TotalBox) == undefined)
                {
                    this.Validate = false;
                    this.$fun.alert("Ingrese numero de caja valido " , "error");
                    return;
                }
                this.Validate = true;
                item.TotalBagWeight = (item.WeightNetBoxesOld / item.NumberBoxesOld) * item.TotalBox;
            },
            CalculateWeights(){
                
                let Weights = 0;
                let NumberBox = 0;
                let WeightsSingle = 0;
                if(this.itemPalletLosses > 0){
                    NumberBox = this.dataPallet.Pallets[0].NumberBoxes;
                    Weights = this.dataPallet.Pallets[0].WeightNetBoxes;
                    WeightsSingle = Weights / NumberBox;
                    if(this.itemHead.CountBoxLosses <= NumberBox)
                    {
                        this.itemHead.CountLossesKg = this.itemHead.CountBoxLosses * WeightsSingle;
                    }
                    else
                    {
                        this.itemHead.CountBoxLosses = 0;
                        this.itemHead.CountLossesKg = 0;
                    }
                }
                if(this.itemPalleDetailtLosses)
                {
                    
                    this.details = this.dataPallet.Pallets[0].palletDetailCEs;
                  
                    let detail = this.details.filter((x) => 
                        x.CidID == this.itemPalleDetailtLosses                        
                    );  
                    NumberBox = detail[0].TotalBox;
                    Weights = detail[0].TotalBagWeight;    
                    WeightsSingle = Weights / NumberBox;  
                    if(this.itemHead.CountBoxLosses <= NumberBox)
                    {
                        this.itemHead.CountLossesKg = this.itemHead.CountBoxLosses * WeightsSingle;
                    }
                    else{
                        this.itemHead.CountBoxLosses = 0;
                    }
                }
                
            },
            saveLosse(){
                if(this.itemPalletContent.length == 0)
                {
                    this.$fun.alert('Seleccione para enviar a mermas','warning');
                    return
                }
                

                // let val = {
                //     CipID: this.itemPalletLosses,
                //     CidID: this.itemPalleDetailtLosses,
                //     NumberBoxesLosses: this.itemHead.CountBoxLosses,
                //     WeightNetBoxesLosses: this.itemHead.CountLossesKg,
                //     Observations: this.itemHead.Observaciones,
                //     UsrCreateID: this.$fun.getUserID(),
                //     UsrUpdateID: this.$fun.getUserID(),
                //     PalletGroupID: this.PalletGroupID,
                // }
              
                // if(this.itemPalletLosses == 0){
                //     this.$fun.alert('Seleccione item','warning');
                //     return
                // }
                
                // if(this.itemHead.CountBoxLosses <= 0 ){
                //     this.$fun.alert('Ingrese numero de cajas','warning');
                //     this.$refs.txtCountBoxLosses.focus();
                //     return
                // }
                // if(this.itemHead.CountLossesKg <= 0 ){
                //     this.$fun.alert('Ingrese Kg de mermas','warning');
                //     this.$refs.txtCountLossesKg.focus();
                //     return
                // }
                // if(this.itemHead.Observaciones == undefined ){
                //     this.$fun.alert('Ingrese observaciones','warning');
                //     this.$refs.txtObservaciones.focus();
                //     return
                // }
                console.log(this.itemPalletContent);
                this.$fun.alert("Esta de seguro de guardar ?", "question")
                .then(res => {
                    if (res.value) {
                        _sFrzChamberPalletLosseService
                        .save(this.itemPalletContent, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Se registro merma correctamente", "success");
                                this.itemPalletContent = [];       
                                this.itemsPalletDetail = [];                   
                            }
                        });
                    }
                });

            },
            ShowViewDetails()
			{
				if(this.showMore)
				{
					this.showMore = false;
				}else{
					this.showMore = true;
				}
				
			},
            listPallet(item){
                

                _sFrzChamberPalletLosseService.list(item, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                            this.dataPallet = r.data;
                            
                        }
                    });
            }

        },
        created(){
           
        },
        watch:{
            // dataPallet(){
            //     this.PalletGroupID = this.PalletGroupID // item.Pallets[0].PalletGroupID
            //     console.log('item wacth',this.PalletGroupID);
            //     this.listPallet(this.PalletGroupID);
            // }
            
        }
        


    }
</script>